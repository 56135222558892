import React, { useRef, useState } from "react";
import TrelloCard from "../../components/TrelloCard";
import apiDriverFreightStatusChange from "./useDriverFreightStatusChange";

const FreightInPreparation = ({ loadList, setLoadList, action }) => {
    const [update, setUpdate] = useState(false);
    const lanes = useRef({
        REFUSED_BY_DRIVER: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=REFUSED_BY_DRIVER&page_size=9999999999",
            title: "Recusado pelo motorista",
        },
        DRAFT: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=DRAFT&page_size=9999999999",
            title: "Em rascunho",
        },
        CREATED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=CREATED&page_size=9999999999",
            title: "Cadastrada",
        },
        SENT_TO_DRIVER: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=SENT_TO_DRIVER&page_size=9999999999",
            title: "Enviado ao motorista",
        },
        ACCEPTED_BY_DRIVER: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=ACCEPTED_BY_DRIVER&page_size=9999999999",
            title: "Aceito pelo motorista",
        },
        CANCELLED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=CANCELLED&page_size=9999999999",
            title: "Cancelada",
        },
    });

    const handleDragEnd = (cardId, sourceLaneId, targetLaneId, ...rest) => {
        apiDriverFreightStatusChange(sourceLaneId, targetLaneId, cardId).finally(() => {
            setUpdate(!update);
        });
    };

    if (loadList === 1) {
        setLoadList(false);
    }

    return <TrelloCard lanes={lanes} handleDragEnd={handleDragEnd} update={update} action={action} />;
};

export default FreightInPreparation;
